import React, {useState} from 'react';
import CartItem from './components/Cart/Cart';

function App() {
  
  return (
    <div className="App">
      <h1 className="App-header">Daraa</h1>
      <h2>Cart:</h2>
      <CartItem
        sku={1}
        image="https://media.istockphoto.com/photos/mens-shortsleeve-tshirt-mockup-in-front-and-back-views-picture-id1328049157?s=612x612"
      />

      <CartItem
        sku={2}
        image="https://media.istockphoto.com/photos/red-tshirt-clothes-picture-id685779142?s=612x612"
      />
    </div>
  );
}

export default App;
