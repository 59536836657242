import React, {useState} from 'react';

const SERVER_URL = "https://api.daraa.io"

const CartItem = (props) => {
    const [showOptions, setShowOptions] = useState(false)
    const [options, setOptions] = useState([])
    const [quantity, setQuantity] = useState(0)

    const populateSelect = () => {
        if (options.length == 0) {
            return "Out of Stock"
        }
        const opts = options.sort((first, second) => {
            if (first.time < second.time) {
                return -1
            }
            else {
                return 1
            }
        }).filter(cur => {
            return cur.quantity >= quantity
        })
        const fastest = opts.shift()
        const remainder = opts.sort((first, second) => {
            if (first.cost < second.cost) {
                return -1
            }
            else {
                return 1
            }
        })
        remainder.unshift(fastest)

        const strings = remainder.map((item, i) => {
            let str = `Cost: $ ${item.cost} Arrives in: ${item.time / 60 / 24} days`
            return (<option key={i} value={item.method}>{str}</option>)
        })
        console.log(strings)
        return strings
    }

    const getAvailabitity = async (e) => {
        e.preventDefault()
        try {
            const request = new Request(`${SERVER_URL}/sku/1/${props.sku}/07078`)
            console.log(request)
            const response = await fetch(request)
            console.log(response)
            const data = await response.json()
            console.log(data)
            setOptions(data)
            setShowOptions(!showOptions)
            populateSelect()
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div>
            <img
                src={props.image}
                className="Red Tshirt"
                alt="logo"
            />
            <span>Quantity: </span>
            <input
                placeholder='Amount'
                type='number'
                onChange={(e) => setQuantity(e.target.value)}
            ></input>
            <button onClick={(e) => getAvailabitity(e)}>Check Availability</button>
            {
                showOptions
                ?
                    <select>
                        {populateSelect()}
                    </select>
                :
                ""
            }
        </div>
    );
}

export default CartItem